<template>
    <div>  
        <div class="is-mobile">  
            <div class="row">
                <div class="col-12 mt-2 pb-0">
                    <span class="bill-date">{{this.item.dateIssued | formatDate}}</span><span :class="status_class">{{this.item.status}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-8 col-md-9 col-sm-9 mt-0 pt-0">
                        <a :href="filepath + item.fileName" target="_blank" class="link-deco" >
                            <span class="bill-file">{{this.item.fileName}}</span>
                        </a>
                </div>
                <div class="col-4 col-md-3 col-sm-3 pt-0 bill-amount-position">               
                    <div class="row">
                        <div class="col-5 col-md-7 col-sm-7 pr-0">
                            <span class="bill-amount-text">RM</span>
                        </div>
                        <div class="col-7 col-md-5 col-sm-5 pl-0">
                            <span class="bill-amount-text">{{this.item.amount}}</span>
                        </div>              
                    </div>
                </div>
            </div>
        </div>

        <div class="is-desktop">  
            <div class="row pt-3 pb-3">
                <div class="col-2 col-md-2">
                    <span class="bill-date">{{this.item.dateIssued | formatDate}}</span>
                </div>
                <div class="col-6 col-md-6">
                    <a :href="filepath + item.fileName" target="_blank" class="link-deco">
                        <span class="bill-file">{{this.item.fileName}}</span>
                    </a>
                </div>
                <div class="col-1 col-md-1">
                    <span :class="status_class">{{this.item.status}}</span>
                </div>
                <div class="col-3 col-md-3 bill-amount-position">
                    <div class="row">
                        <div class="col-5 col-md-7 pr-0">
                            <span class="bill-amount-text">RM</span>
                        </div>
                        <div class="col-7 col-md-5 pl-0">
                            <span class="bill-amount-text">{{this.item.amount}}</span>
                        </div>              
                    </div>
                </div>
            </div>
        </div>
        <v-divider class="divider"></v-divider>
    </div>
</template>

<script>
import { uploadPath } from "/src/constants/config";

export default {
    props: ['item'],
    data() {
            return {
                filepath: "",
            }
        },

    components: {
    },
  
    mounted() {		
        this.filepath = uploadPath + "/general/"
    }, 

    methods: {
    },

    computed: {
        status_class() {
            return this.item.status == "Overdue" ? "bill-status-overdue" : 
                    ( this.item.status == "Paid" ? "bill-status-paid" : "" );
        },
    },

    watch: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
    <div class="page-height background-detail">
        <v-container class="bill-background bill-header-padding container-header">
            <div class="row mt-0 pb-0">
                <div class="col-12 col-md-9 pb-0 pt-0">
                    <div class="row mt-0 pb-0">
                        <div class="col-12 col-md-12 pb-0 pt-0">
                            <span class="bill-subtitle">Outstanding Amount</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12 pt-0">
                            <span class="bill-title">RM {{outstanding}}</span>
                        </div>
                    </div>
                </div>
                <div class="is-desktop col-12 col-md-3 pb-0 pt-0 mt-auto mb-auto">
                    <v-btn outlined  class="bill-button" @click="performAction">
                        {{actionButtonText}}
                    </v-btn>
                </div>
            </div>
        </v-container>

        <v-container class="page-content-offset bill-body-padding container-body">
            <div v-if="!isSubmitPayment">
                <div class="row mt-0 align-center">
                    <div class="col-12 col-md-8">
                        <div class="row align-center">
                            <div class="col-7 col-sm-9">
                            <span class="bill-all-invoices">All Invoices</span>
                            </div>
                            <div class="col-5 col-sm-3 is-mobile">
                                <v-btn outlined  class="bill-button" @click="performAction">
                                    {{actionButtonText}}
                                </v-btn>
                            </div>
                        </div>                      
                    </div>
                    <div class="col-7 col-md-4 col-sm-4">
                        <div class="period-container">
                            <span class="pr-2">Show: </span>
                            <v-select
                                :items="periods"
                                :item-text="'label'"
                                outlined
                                class="dropdown-month"
                                dark
                                dense
                                height="10"
                                v-model="selectedPeriod"
                            ></v-select>
                        </div>
                    </div>
                </div>

                <div v-for="item in items" :key="item.id">
                    <Bill :item="item"></Bill>
                </div>
            </div>
            
            <SubmitPayment v-if="isSubmitPayment" @submitPayment="submitPayment" @cancelPayment="cancelPayment"></SubmitPayment>
        </v-container>
    </div>
</template>

<script>
import Bill from '/src/views/Bill.vue'
import SubmitPayment from "/src/views/SubmitPayment";
import axios from 'axios';
import currentUserMixin from '/src/mixin/currentUserMixin.js';
import { openRoadApiUrl, version, uploadPath } from "/src/constants/config";
import moment from 'moment';

export default {
    mixins: [currentUserMixin],

    data() {
		return {
            outstanding: 0, 
            items: [],
            periods: [{value: 6, label: "6 months"}, 
                    {value: 12, label: "12 months"},
                    {value: 24, label: "24 months"},
                    {value: -1, label: "All"}],
            isSubmitPayment: false,
            selectedPeriod: 6,
            dateIssuedFrom: "",
            dateIssuedTo: "",
		}
    },

    components: {Bill, SubmitPayment},
  
    mounted() {
        this.getDataWithinPeriod();			
    }, 

    methods: {
        getBills(){
            axios.get(this.url, this.$data.apiHeaders)
                .then(response => this.setBills(response))
                .catch(error => this.onError(error));
        },

        setBills(response) {
            this.outstanding = response.data.result.outstandingAmount.toFixed(2);
            this.items = response.data.result.items.map(function(item){
                let obj = {};

                obj['name'] = item.name;
                obj['reference'] = item.reference;
                obj['description'] = item.description;
                obj['amount'] = item.amount.toFixed(2);
                obj['fileName'] = item.fileName;
                obj['fileMimeType'] = item.fileMimeType;
                obj['paymentProofName'] = item.paymentProofName;
                obj['paymentProofType'] = item.paymentProofType;
                obj['paymentProofReferenceNo'] = item.paymentProofReferenceNo;
                obj['status'] = item.statusLabel;
                obj['isPaid'] = item.isPaid;
                obj['dateIssued'] = item.dateIssued;
                obj['dateDue'] = item.dateDue;
                obj['datePaid'] = item.datePaid;
                obj['creationTime'] = item.creationTime;
                obj['creatorUserId'] = item.creatorUserId;
                obj['id'] = item.id;

                return obj;
            });
        },

        getDataWithinPeriod(){
            if(!this.isAll){
                this.dateIssuedFrom = moment().subtract(this.selectedPeriod, "months").format("YYYY-MM-DD");
                this.dateIssuedTo = moment().format("YYYY-MM-DD");
            }           

            this.getBills();
        },

        performAction(){
            this.isSubmitPayment = !this.isSubmitPayment;
        },

        cancelPayment(){
            this.isSubmitPayment = false;
        },

        submitPayment(){
            this.isSubmitPayment = false;
            this.getDataWithinPeriod();	
        },

        onSuccess(response) {
            let message = "Success";
            this.$notify("success", message, "", {
                duration: 6000,
                permanent: false
            });
        },

        onError(error){
            let message = "Failed";
            let details = error && error.response && error.response.data.error 
                            ? ( error.response.data.error.validationErrors ? error.response.data.error.details : error.response.data.error.message )
                            : "";
            this.$notify("error", message, details, {
                duration: 6000,
                permanent: false
            });
        },
    },

    computed: {
        actionButtonText(){
            return this.isSubmitPayment ? "View Invoices" : "Submit Payment" ;
        },
        url(){
            return this.isAll 
                    ? openRoadApiUrl + "/api/services/" + version + "/invoice/me/list" 
                    : openRoadApiUrl + "/api/services/" + version + "/invoice/me/list?DateIssuedFrom=" + this.dateIssuedFrom 
                        + "&DateIssuedTo=" + this.dateIssuedTo;
        },
        isAll(){
            return this.selectedPeriod == -1;
        }
    },

    watch:{
        selectedPeriod(){
            this.getDataWithinPeriod();
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

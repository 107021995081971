import { render, staticRenderFns } from "./SubmitPayment.vue?vue&type=template&id=7643b794&scoped=true&"
import script from "./SubmitPayment.vue?vue&type=script&lang=js&"
export * from "./SubmitPayment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7643b794",
  null
  
)

export default component.exports
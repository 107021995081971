<template>
    <div class="page-height">
        <div class="row mt-0 align-center">
            <div class="col-7 col-md-9 col-sm-9 mt-0">
            <span class="bill-all-invoices">Upload Payment Proof</span>
            </div>
            <div class="col-5 col-md-3 col-sm-3 is-mobile">
                <v-btn outlined  class="bill-button" @click="viewInvoices">
                    View Invoices
                </v-btn>
            </div>
        </div>

        <div class="submit-payment-content-padding">
            <div class="row mt-4 align-center">
                <div class="col-12 col-md-12">
                    <span class="color-theme-2">Please upload image or PDF file only</span>
                </div>
            </div>
            <div class="row mt-0 mb-0">
                <div class="col-12 col-md-6">
                    <div class="upload-bill-btn d-flex align-center pa-2 font-roboto">
                      <v-btn class="font-roboto text-capitalize" dark color="secondary" @click="chooseFile">
                        Choose file...
                      </v-btn>
                      <span class="pl-2 font-roboto" v-text="fileSelectorText">
                        No file selected
                      </span>
                    </div>

                    <input ref="fileInput" type="file" id="myFile"  accept="application/pdf" name="filename" class="submit-file"  @change=uploadFile>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-12">
                    <span class="color-theme-2">Kindly indicate the total amount of payment</span>
                </div>
            </div>

            <div class="row align-center mt-0">
                <div class="col-7 col-md-12">
                    <span class="font-roboto pr-2">RM</span>
                    <input type="number" class="payment-amount" v-model="amount" step="0.01">
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-12">
                    <span class="color-theme-2">Kindly indicate the invoice number</span>
                </div>
            </div>

            <div class="row align-center mt-0">
                <div class="col-12 col-md-6">
                    <input type="text" class="payment-amount reference-number" v-model="referenceNo" placeholder="e.g. 000125, 000126">
                </div>
            </div>

            <div class="row pt-5">
                <div class="col-12 col-md-12 submit-payment-button-position">
                    <v-btn outlined class="payment-button submit-payment-button" @click="submit">
                        Submit
                    </v-btn>
                    <v-btn outlined class="payment-button" @click="cancel">
                        Cancel
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import currentUserMixin from '/src/mixin/currentUserMixin.js';
import { openRoadApiUrl, version, uploadPath, uploadTypeGeneral } from "/src/constants/config";
import moment from 'moment';

export default {
    mixins: [currentUserMixin],

    data() {
      return {
            amount: 0, 
            uploadedFile: null, 
            uploadedFileName: "",
            referenceNo: "",
      }
    },

    components: {
    },
  
    mounted() {		
    }, 

    methods: {
        viewInvoices(){
            this.$emit("cancelPayment");
        },

        cancel(){
            this.$emit("cancelPayment");
        },

        submit(){
            if(this.uploadedFile){
                let params = {
                    uploadType: this.uploadedFile.uploadType,
                    fileName: this.uploadedFile.fileName,
                    fileFormat: this.uploadedFile.fileFormat,
                    fileData: this.uploadedFile.fileData
                };

            let url = openRoadApiUrl + "/api/services/" + version + "/upload";
                axios.post(url, params, this.$data.apiHeaders )
                .then(response => this.onUploadSuccess(response))
                .catch(error => this.onError(error));
            }    
        
            else{
                this.createPayment();
            }
        },

        onUploadSuccess(response){
            this.uploadedFileName = response.data.result;

            this.createPayment();
        },

        createPayment(){
            let params = {
                amount: this.amount ? this.amount : 0,
                fileName: this.uploadedFileName,
                fileData: this.uploadedFile ? this.uploadedFile.fileData : "",
                datePayment: moment().format("YYYY-MM-DDTHH:mm:ss"),
                referenceNo: this.referenceNo
            };

            let url = openRoadApiUrl + "/api/services/" + version + "/payment/me/create";
            axios.post(url, params, this.$data.apiHeaders )
                        .then(response => this.onSuccess(response))
            .catch(error => this.onError(error));
        },

        chooseFile() {
            this.$refs.fileInput.click();
        },

        uploadFile(e){
            let fileFullName = e.target.files[0].name;
            let fileName = fileFullName.split('/').pop().split('\\').pop().replace(/[.][^.]+$/, "");
            let fileFormat = fileFullName.substring(fileName.length + 1, fileFullName.length);
            this.displayFileName = fileFullName;

            const file = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e =>{
                let obj = {};
                obj["uploadType"] = uploadTypeGeneral.toUpperCase(),
                obj["fileName"] = fileName,
                obj["fileFormat"] = fileFormat,
                obj["fileData"] = e.target.result.substring(e.target.result.indexOf(",") + 1),

                this.uploadedFile = obj;
            };     
        },

        onSuccess(response) {
                let message = "Success";
                this.$notify("success", message, "", {
                duration: 6000,
                permanent: false
            });
            this.$emit("submitPayment");
        },

        onError(error){     
        let message = "Failed";
        let details = error && error.response && error.response.data.error 
                        ? ( error.response.data.error.validationErrors ? error.response.data.error.details : error.response.data.error.message )
                        : "";
        this.$notify("error", message, details, {
            duration: 6000,
            permanent: false
        });
        }
    },

    computed: {
      fileSelectorText() {
        let name = "No file selected";
        if(this.uploadedFile) {
          name = this.uploadedFile.fileName + "." + this.uploadedFile.fileFormat;
        }

        return name;
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
